<template>
  <div
    class="p-3 items-center bg-white text-sm font-semibold rounded-md flex gap-2 text-gray-500"
    :class="{ 'cursor-pointer hover:bg-gray-100': !active }"
  >
    <div
      class="rounded-md uppercase text-xs px-1.5 py-1"
      :class="{
        'bg-primary bg-opacity-20 text-primary': active,
        'bg-gray-100 text-gray-700': !active
      }"
    >
      {{ version.language }}
    </div>
    <div class="flex-1 line-clamp-1" :class="{ 'text-gray-800': version.title && version.title.length > 0 }">
      {{ languageLabel(version.language) }}
    </div>
    <fw-tag size="xs" :type="stateColor">{{ stateLabel }}</fw-tag>
    <div v-if="version.is_original" class="rounded-md text-xs px-1.5 py-1 bg-gray-800 text-gray-100">ORIG</div>
  </div>
</template>
<script>
import locales from '@/fw-modules/fw-core-vue/utilities/locales.json'
export default {
  name: 'RecordVersion',
  props: {
    version: {
      type: Object,
      default: function() {
        return {}
      }
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      statesLabes: {
        pt: {
          draft: 'Rascunho',
          review: 'Em revisão',
          closed: 'Bloqueado',
          published: 'Publicado',
          deleted: 'Eliminado',
          archived: 'Arquivado'
        },
        en: {
          draft: 'Draft',
          review: 'Under review',
          closed: 'Locked',
          published: 'Published',
          deleted: 'Deleted',
          archived: 'Archived'
        }
      }
    }
  },
  computed: {
    stateLabel() {
      return this.statesLabes[this.$i18n.locale][this.version.state]
    },
    stateColor() {
      return this.version.state === 'draft'
        ? 'light-orange'
        : this.version.state === 'published'
        ? 'light-primary'
        : this.version.state === 'closed'
        ? 'light-gray'
        : 'light-blue'
    }
  },
  methods: {
    languageLabel(language) {
      return locales.find(el => el.id == language).name
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "no_title": "Sem título"
  },
  "en": {
    "no_title": "No title"
  }
}
</i18n>
